/* eslint-disable react/require-default-props */
import React, { CSSProperties, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Label, FormGroup } from 'reactstrap';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import styles from 'styled-components';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import { useSelector } from 'react-redux';
import { State } from 'redux/types';
import { NEPAL_TZ } from 'constant';
import NepaliDate from 'nepali-date';
import { addDays } from 'date-fns';

interface Props {
  id: string;
  label?: string;
  labelStyle?: CSSProperties;
  inputIcon?: ReactElement;
  placeholder?: string;
  disabled?: boolean;
  maxDate?: any;
  minDate?: any;
  onChange: any;
  selected?: any;
  isNormalDatePicker?: boolean;
  borderColor?: string;
  borderWidth?: number;
  borderRadius?: number;
  value?: any;
  top?: number;
}

const TvsDatepickerElement = ({
  id,
  label,
  labelStyle,
  inputIcon,
  placeholder,
  disabled,
  maxDate,
  minDate,
  onChange,
  selected,
  isNormalDatePicker = false,
  borderColor = '#ced4da',
  borderRadius = 2,
  borderWidth = 1,
  top,
  value,
}: Props) => {
  const { auth } = useSelector((state: State) => state);
  const { t } = useTranslation();
  const normalDatePicker = (
    <DatePickerStyle
      onChange={(date) => onChange(date)}
      id={id}
      maxDate={maxDate}
      minDate={minDate}
      selected={selected}
      dateFormat="dd/MM/y"
      placeholderText={placeholder || t('Select Date')}
      autoComplete="off"
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      borderColor={borderColor}
      value={value}
    />
  );
  // const getDefaultDate = () => {
  //   if (values[id]) {
  //     return new NepaliDate(new Date(values[id])).format('YYYY-MM-DD');
  //   }
  //   return new NepaliDate(minDate)?.format('YYYY-MM-DD');
  // };

  const getSelectedDate = () => {
    if (selected) {
      return new NepaliDate(new Date(selected)).format('YYYY-MM-DD');
    }
    if (minDate) {
      return new NepaliDate(minDate)?.format('YYYY-MM-DD');
    }
    return new NepaliDate().format('YYYY-MM-DD');
  };

  const nepalDatePicker = () => {
    const min = minDate ? new NepaliDate(addDays(minDate, -1)) : null;
    const max = maxDate ? new NepaliDate(addDays(maxDate, 1)) : null;
    // const defaultValue = new NepaliDate(new Date(today.setDate(today.getDate() + 1)));
    return (
      <NepalWrapper>
        <Calendar
          onChange={(date) => onChange(new Date(date.adDate))}
          language="en"
          style={{ width: '100%', height: '50px', padding: '0px 10px' }}
          dateFormat="DD/MM/YYYY"
          minDate={min?.format('YYYY-MM-DD')}
          maxDate={max?.format('YYYY-MM-DD')}
          disabled={disabled}
          // defaultDate={values[id] ? new NepaliDate(new Date(values[id])).format('YYYY-MM-DD') : null}
          defaultDate={getSelectedDate()}
          // hideDefaultValue={true}
        />
      </NepalWrapper>
    );
  };

  return (
    <FormGroup className="position-relative" style={{ marginBottom: '0em' }}>
      {label && <Label style={labelStyle}>{label}</Label>}
      <Div className={disabled ? 'disabled' : ''}>
        {!isNormalDatePicker && auth?.settings?.timezone === NEPAL_TZ ? nepalDatePicker() : normalDatePicker}
      </Div>
      <Icon top={top}>
        <img src={`${process.env.PUBLIC_URL}/images/calendar.png`} alt="calendar" width="16" height="18" />
      </Icon>
    </FormGroup>
  );
};

const NepalWrapper = styles.div`
  position: relative;
  // &:after {
  //   content: 'Select Date';
  //   display: block;
  //   clear: both;
  //   position: absolute;
  //   inset: 0 0 0 0;
  //   border: 1px solid #ced4da;
  //   background: #fff;
  //   padding: 13px 10px;
  // }

`;

const Div = styles.div`
  &.disabled {
    position: relative;
    &:after {
      content: 'Select Date';
      clear: both;
      padding: 13px 10px;
      display: flex;
      position: absolute;
      inset: 0 0 0 0;
      background: #e5e3e3;
      border: 1px solid #ced4da;
    }
  }
`;
const DatePickerStyle = styles(DatePicker)<{
  borderColor: string;
  borderWidth: number;
  borderRadius: number;
}>`
  height: 50px;
  border: ${(props) => props.borderWidth}px solid ${(props) => props.borderColor};
  width: 100%;
  padding: 10px;
  border-radius: ${(props) => props.borderRadius}px;
`;

const Icon = styles.div<{
  top: number;
}>`
  position: absolute;
  top: ${(props) => (props.top ? props.top : 50)}%;
  bottom: 0;
  right: 10px;
`;
export default TvsDatepickerElement;
