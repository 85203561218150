export const defaults = {
  PRIVACY_POLICY_URL: 'https://www.tvsd.ai/en/privacy-policy',
  TERMS_OF_USE_URL: 'https://www.tvsd.ai/en/terms-of-use',
  COOKIE_POLICY_URL: 'https://www.tvsd.ai/en/cookie-policy',
};

export const configLocalization = {
  personalData: 'Personal Data',
  employment: 'Employment',
  asset: 'Asset',
  dealer: 'Dealer',
  loanDetails: 'Loan Details',
  charges: 'Charges',
  oneTimeSettlement: 'One Time Settlement',
  foreclosureType: 'Fore Closure Type',
  group: 'Group',
  agency: 'Agency',
  suspend: 'Suspend',
  roleInformation: 'Role Information',
  location: 'Location',
  agencyData: 'Agency Data',
  deviceData: 'Device Data',
  editUser: 'Edit User',
  addUser: 'Add User',
  BOD: 'BOD',
  ageencyValidForm: 'Ageency Valid Form',
  agencyValidTo: 'Agency Valid To',
};

export const ApprovalUnit = [
  'Engine',
  'Consumer Durable',
  'Tractor',
  'Two Wheeler',
  'Used Car',
  'Auto Loan',
  'MSME',
  'User Commercial Vehicle',
];
